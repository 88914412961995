























































































































































































































































































import Base from '@/mixins/Base.vue';
import { IClient } from '@/interfaces/client';
import { IProject, IProjectsResponse } from '@/interfaces/project';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({

    data() {
        return {
            projectsParams: {
                active: null,
                source: null,
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                clients: [] as Array<IClient>,
                page: 1,
            },

            projects: {
                data: [] as Array<IProject>,
                meta: {} as IPagination | any,
            } as IProjectsResponse,
        };
    },
    mounted() {
        this.getProjects();
    },
    methods: {
        getProjects(page: number = 1): void {
            this.projectsParams.page = page;

            this.get<IProjectsResponse>('projects', {
                params: {
                    ...this.projectsParams,
                    clients: this.projectsParams.clients.map((c) => c.id),
                },
            }).then(({ data }) => {
                this.projects = data;
            });
        },
    },
});

export default component;
